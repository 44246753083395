// Form.js

import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Box, Button, VStack, Heading, Text, HStack, useBreakpointValue, Icon, Center, Textarea, AccordionButton, AccordionIcon, AccordionPanel, useToast } from '@chakra-ui/react';
import { getForm, saveUserFeedback, fetchUserFeedback } from '../firebase.utils'; // Assuming this is implemented to fetch form data
import { EditIcon, ViewIcon, LinkIcon } from '@chakra-ui/icons';
import FormPreview from './FormPreview';
import { useTranslation } from 'react-i18next';
const feedbackEmojis = {
  good: '😊',
  neutral: '😐',
  bad: '😢'
};

const Form = ({ userToken, iframe, rootUrl }) => {
  const { runId } = useParams();
  const [formJSON, setFormJSON] = useState(null);
  const [formData, setFormData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const buttonSize = useBreakpointValue({ base: 'sm', md: 'md' });
const iconSize = useBreakpointValue({ base: '20px', md: '25px' });
const { t } = useTranslation();

const [formFeedback, setFormFeedback] = useState({});
const [previousFeedback, setpreviousFeedback] = useState(false);
const toast = useToast();


  useEffect(() => {
    const fetchFeedback = async () => {
      setIsLoading(true);
      const feedback = await fetchUserFeedback(runId, userToken);
      console.log('feedback', feedback);
      if(feedback){
        setpreviousFeedback(true);
      }
      setFormFeedback(feedback || {});
      setIsLoading(false);
    };
    const fetchFormData = async () => {
      try {
        const data = await getForm(runId, userToken);
        setFormData(data);
        if(data.formData){
            setFormJSON(data.formData);
        }
        else{
            const formStr = data.openAiData.data[0].content[0].text.value.replace(/`/g, '').replace(/^json/, '').trim().replace(/^[\n\`]+|[\n\`]+$/g, '');
            const formParsed = JSON.parse(formStr);
            setFormJSON(formParsed.form);
        }
      } catch (error) {
        console.error("Error fetching form data:", error);
      } finally {
        setIsLoading(false);
      }
    };
    fetchFormData();
    fetchFeedback();
  }, [runId, userToken]);

  const baseRedirectUrl = 'https://mydatascope.com/addon_redirect';

  const buildRedirectUrl = (path) => iframe ? `${rootUrl}${path}` : `${baseRedirectUrl}?token=${encodeURIComponent(userToken)}&callback_url=${encodeURIComponent(path)}`;

  const handleFeedbackRatingChange = async (rating) => {
    try {
      const response = await saveUserFeedback(runId, rating, formFeedback.details || '', formJSON.title,  userToken);
      if (response.success) {
        setFormFeedback(prevFeedback => ({ ...prevFeedback, rating, title: formJSON.title}));
        toast({
          title: t('feedback.feedbackSaved'),
          description: t('feedback.feedbackSavedDescription'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast({
        title: t('feedback.errorSavingFeedback'),
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleSubmitFeedbackDetails = async () => {
    try {
      const response = await saveUserFeedback(runId, formFeedback.rating, formFeedback.details,formJSON.title,  userToken);
      if (response.success) {
        toast({
          title: t('feedback.feedbackSubmitted'),
          description: t('feedback.feedbackSubmittedDescription'),
          status: 'success',
          duration: 5000,
          isClosable: true,
        });
      } else {
        throw new Error(response.message);
      }
    } catch (error) {
      toast({
        title: t('feedback.errorSavingFeedbackDetails'),
        description: error.message,
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  if (isLoading) {
    return <p>Loading form data...</p>;
  }

  if (!formData || !formData.createFormData || !formData.createFormData.form_created || formData.createFormData.form_id <= 0) {
    return <p>Form data is unvailable or invalid.</p>;
  }
  const { title, items } = formJSON;
  const modifyUrl = buildRedirectUrl(`/task_forms/${formData.createFormData.form_id}/edit`);
  const previewUrl = buildRedirectUrl(`/task_forms/${formData.createFormData.form_id}/form_answers/new`);
  const shareUrl = buildRedirectUrl(`/task_forms/${formData.createFormData.form_id}/edit?edit_type=share`);

  return (
    <VStack spacing={4} align="stretch">
         <Heading as="h1">{title}</Heading>
        {/* Feedback Section */}
{!isLoading && !previousFeedback  && (
  <Center>
    <Box
      w={['90%', '80%', '60%', '40%']} // Responsive width
      p={6}
      mt={5}
      mb={10}
      boxShadow="lg"
      rounded="md"
      bg="white" // Use appropriate color mode value
      borderWidth="1px"
      borderColor="gray.200"
    >
      <Text fontSize="sm" mb={4} fontWeight="semibold" textAlign="center">{t('feedback.provideFeedback')}</Text>
      <HStack spacing={5} justify="center">
        {['good', 'neutral', 'bad'].map((value) => (
          <Button
            key={value}
            size="lg" // Increase the button size for better touch targets
            variant={formFeedback.rating === value ? "solid" : "outline"}
            colorScheme={value === 'good' ? "green" : value === 'neutral' ? "orange" : "red"} // Color scheme based on feedback
            onClick={() => handleFeedbackRatingChange(value)}
            isDisabled={formFeedback.rating !== undefined} // Disable after selection
          >
            {t(`feedback.${value}`)}
          </Button>
        ))}
      </HStack>
      <Textarea
        mt={4}
        value={formFeedback.details || ''}
        onChange={(e) => setFormFeedback(prev => ({ ...prev, details: e.target.value }))}
        placeholder={t('feedback.detailsPlaceholder')}
        borderColor="gray.300" // Style the border color
      />
      {formFeedback.details && (
        <Center mt={4}>
          <Button
            colorScheme="blue"
            onClick={handleSubmitFeedbackDetails}
          >
            {t('feedback.submitDetails')}
          </Button>
        </Center>
      )}
    </Box>
  </Center>
)}
      <HStack spacing={4} justify="center" wrap="wrap">
    <Button as="a" href={modifyUrl} target="_blank" colorScheme="blue" size={buttonSize} leftIcon={<Icon as={EditIcon} w={iconSize} h={iconSize} />}>
      Modify Form
    </Button>
    <Button as="a" href={previewUrl} target="_blank" colorScheme="blue" size={buttonSize} leftIcon={<Icon as={ViewIcon} w={iconSize} h={iconSize} />}>
      Preview Form
    </Button>
    <Button as="a" href={shareUrl} target="_blank" colorScheme="blue" size={buttonSize} leftIcon={<Icon as={LinkIcon} w={iconSize} h={iconSize} />}>
      Share
    </Button>
  </HStack>
  <Box p={5} shadow="md" borderWidth="1px" borderRadius="md">
    <FormPreview formData={formJSON} />
  </Box>
    </VStack>
  );
};

export default Form;