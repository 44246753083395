// FormCard.js
import React from 'react';
import { Box, VStack, Text, Heading,  useColorModeValue, LinkBox ,LinkOverlay, Link } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const FormCard = ({ form }) => {
  const { t } = useTranslation();
  const bgColor = useColorModeValue('gray.100', 'gray.700');
  const navigate = useNavigate();

  const openForm = () => {
    navigate(`/forms-library/${form.libraryId}`);
  };

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      backgroundColor={bgColor}
      boxShadow="md"
      transition="all 0.3s"
      _hover={{ boxShadow: 'lg' }}
    >
    <Link href={`/forms-library/${form.libraryId}`} isExternal>
      <Box as='article' maxW='sm' p='5' borderWidth='1px' rounded='md'>
        <VStack align='start' spacing={3}>
          <Heading size="md">
            {form.title}
          </Heading>
          {(form.formData || {}).description && (
            <Text noOfLines={3} fontSize="sm" color="gray.500">
              {(form.formData || {}).description}
            </Text>
          )}
          {form.importedAt && (
            <Text fontSize="xs" color="gray.500">
              {t('created_at', { date: form.importedAt.toDate().toLocaleDateString() })}
            </Text>
          )}
        </VStack>
      </Box>
    </Link>
    </Box>
  );
};

export default FormCard;