import logo from './logo.svg';
import './App.css';
import React, { useState, useEffect } from 'react'; 
import { ChakraProvider, Center, Spinner } from "@chakra-ui/react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminComponent from './admin/AdminComponent';
import NewAssessmentGenerator from './admin/assessment-builder/NewAssessmentGenerator';
import ProcessorFormAIGenerator from './admin/assessment-builder/ProcessorFormAIGenerator';
import Login from './Login';
import Form from './components/Form';
import Forms from './components/Forms';
import { onAuthStateChanged } from 'firebase/auth';
import {auth, db , appFirebase, appCheck } from './firebase.utils';
import Library from './components/Library';
import FormLanding from './components/FormLanding';
import Layout from './components/Layout';
import mixpanel from 'mixpanel-browser';
import { useNavigate, useLocation } from 'react-router-dom';
import i18n from './i18n';
import theme from './theme'

function IframeComponent({ handleUserAuthenticated, setRootUrl }) {
  let navigate = useNavigate();
  let location = useLocation();

  useEffect(() => {
    // Parse the query parameters
    let params = new URLSearchParams(location.search);
    let token = params.get('token');
    let url = params.get('url');

    // Call the handleUserAuthenticated function with the token
    handleUserAuthenticated(token);
    setRootUrl(url);

    // Then redirect to the desired path
    navigate('/iframe/new_form');
  }, [navigate, handleUserAuthenticated, location]);

  // This component does not render anything
  return null;
}

function App() {
  const [loading, setLoading] = useState(null);
  const [userToken, setUserToken] = useState(null);
  const [rootUrl, setRootUrl] = useState(null);
  const [language, setLanguage] = useState(localStorage.getItem('language') || 'en');
  mixpanel.init('7b09a4587d440d04d6ae45a747ed1f08', {debug: true, track_pageview: true, persistence: 'localStorage'});

  const setLanguageAction = (language) => {
    console.log('language', language)
    localStorage.setItem('language', language);
    setLanguage(language);
  };

 

  // When the component mounts, check for a stored token
useEffect(() => {
  setLoading(true);
  const storedToken = localStorage.getItem('userToken');
  if (storedToken) {
    setUserToken(storedToken);
    mixpanel.identify(userToken);
  }
  
  // Check URL parameter first
  const searchParams = new URLSearchParams(window.location.search);
  const languageParam = searchParams.get('language');
  
  if (languageParam) {
    // If URL has language parameter, use it regardless of other settings
    setLanguageAction(languageParam);
    i18n.changeLanguage(languageParam);
  } else {
    // If no URL parameter, check localStorage or fall back to browser language
    const storedLanguage = localStorage.getItem('language');
    const browserLang = navigator.language || navigator.userLanguage;
    let defaultLang = 'en';
    
    if (browserLang.toLowerCase().includes('es')) defaultLang = 'es';
    if (browserLang.toLowerCase().includes('pt')) defaultLang = 'pt';
    
    const finalLanguage = storedLanguage || defaultLang;
    setLanguageAction(finalLanguage);
    i18n.changeLanguage(finalLanguage);
  }

  setLoading(false);
}, []);

  // This method will be passed to the Login component and called on successful authentication
  const handleUserAuthenticated = (userToken, redirect = null) => {
    setUserToken(userToken);
    localStorage.setItem('userToken', userToken);
    mixpanel.identify(userToken);
    setLoading(false);
    redirect = !!redirect && redirect.startsWith('/forms-library/') ? redirect : null;
    if(!!redirect){
      //redirect to redirect param
      window.location.assign(redirect);
    }
  };

  // Logout handler to reset user states
  const handleLogout = () => {
    setUserToken(null);
    setLoading(false);
    console.log('trying to logout');
    localStorage.removeItem('userToken'); // Optionally set to true if you want to enter a loading state post-logout
  };

  
  if (loading) { // Show loading spinner while determining authentication state
    return (
      <Center h="100vh">
        <Spinner size="xl" />
      </Center>
    );
  };

  return (
    <ChakraProvider theme={theme}>
       <Router>
          <Routes>
             <Route path="/admin/new_form" element={
              userToken ? (
              <AdminComponent userToken={userToken} handleLogout={handleLogout}>
                <NewAssessmentGenerator userToken={userToken}  />
              </AdminComponent>) :  <Login onUserAuthenticated={handleUserAuthenticated}  language={language} />
            }/>
          <Route path="/iframe" element={<IframeComponent handleUserAuthenticated={handleUserAuthenticated} setRootUrl={setRootUrl} />} />
            <Route path="/iframe/process_generation/:runID/thread/:threadID" element={
              userToken ? 
              (<AdminComponent userToken={userToken} iframe={true}>
                <ProcessorFormAIGenerator userToken={userToken} iframe={true} />
              </AdminComponent>) :  <Login onUserAuthenticated={handleUserAuthenticated}  language={language} />
            } />
              <Route path="/admin/process_generation/:runID/thread/:threadID" element={
              userToken ? 
              (<AdminComponent userToken={userToken}>
                <ProcessorFormAIGenerator userToken={userToken}  />
              </AdminComponent>) :  <Login onUserAuthenticated={handleUserAuthenticated}  language={language} />
            } />
            <Route path="/iframe/form/:runId" element={
              userToken ? (
               <AdminComponent userToken={userToken} handleLogout={handleLogout} iframe={true}>
                <Form userToken={userToken} iframe={true}  rootUrl={rootUrl} />
              </AdminComponent>) :  <Login onUserAuthenticated={handleUserAuthenticated}  language={language} />
            }/>
             <Route path="/form/:runId" element={
              userToken ? (
               <AdminComponent userToken={userToken} handleLogout={handleLogout}>
                <Form userToken={userToken}  />
              </AdminComponent>) :  <Login onUserAuthenticated={handleUserAuthenticated}  language={language} />
            }/>
             <Route path="/forms" element={
              userToken ? (
               <AdminComponent userToken={userToken} handleLogout={handleLogout}>
                <Forms userToken={userToken}  />
              </AdminComponent>) :  <Login onUserAuthenticated={handleUserAuthenticated}  language={language} />
            }/>
              <Route path="/forms-library" element={
                <Layout userToken={userToken} handleLogout={handleLogout} language={language}>
                <Library   userToken={userToken}   language={language}/>
                </Layout>
            }/>
               <Route path="/forms-library/:formId" element={
                  <Layout userToken={userToken} handleLogout={handleLogout} language={language}>
                <FormLanding  userToken={userToken}  language={language} />
                </Layout>
                }/>
            <Route path="/iframe/new_form" element={
              userToken ? (
              <AdminComponent userToken={userToken} handleLogout={handleLogout} iframe={true}>
                <NewAssessmentGenerator userToken={userToken} iframe={true}  />
              </AdminComponent>) :  <Login onUserAuthenticated={handleUserAuthenticated}  language={language} />
            }/>
            <Route path="/" element={
              userToken ? (
               <AdminComponent userToken={userToken} handleLogout={handleLogout}>
                <NewAssessmentGenerator userToken={userToken}  />
              </AdminComponent>) :  <Login onUserAuthenticated={handleUserAuthenticated} language={language} />
            }/>
          </Routes>
      </Router>
    </ChakraProvider>
  );
}

export default App;
