import React, { useEffect, useState } from 'react';
import { Box, VStack, Heading, Text, Input, List, ListItem, Button, Link } from '@chakra-ui/react';
import { getLibraryForms, searchLibraryForms } from '../firebase.utils';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import SEO from './SEO';

const Library = ({userToken, language}) => {
  const [forms, setForms] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(false);
  const [noMore, setNoMore] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    fetchLibraryForms();
    // Reset state when language changes
    return () => {
      setForms([]);
      setLastVisible(null);
      setNoMore(false);
    };
  }, [language]);

  const fetchLibraryForms = async () => {
    try {
      setLoading(true);
      const { forms, lastVisible } = await getLibraryForms(null, language);
      setForms(forms);
      setLastVisible(lastVisible);
      setNoMore(forms.length < 20); // Assuming a batch size of 20
    } finally {
      setLoading(false);
    }
  };

  const loadMore = async () => {
    if (loading || noMore) return;
    try{
      setLoading(true);
      const { forms: newForms, lastVisible: newLastVisible } = await getLibraryForms(lastVisible, language);
      if (!newForms.length) setNoMore(true);
      setForms(prevForms => [...prevForms, ...newForms]);
      setLastVisible(newLastVisible);
    } finally {
      setLoading(false);
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      const searchedForms = await searchLibraryForms(searchTerm, language);
      setForms(searchedForms);
      setNoMore(true); // Assume no pagination for search results for simplicity
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box p={5}>
       <SEO  language={language} />
      <VStack spacing={4} align="stretch">
        <Heading mb={4}>{t('library.heading')}</Heading> {/* Use t function to translate */}
        <Input
          placeholder={t('library.searchPlaceholder')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          onKeyPress={(e) => e.key === 'Enter' && handleSearch()}
        />
        <Button onClick={handleSearch} isLoading={loading}>{t('library.searchButton')}</Button>
        <List spacing={3}>
          {forms.map((form) => (
            <ListItem key={form.id} borderWidth="1px" borderRadius="md" p={2}>
              <Link as={RouterLink} to={`/forms-library/${form.id}`}>
                <Text fontWeight="semibold" color="blue.600">{form.title}</Text>
                <Text>{form.formData.description}</Text>
              </Link>
            </ListItem>
          ))}
        </List>
        <Button onClick={loadMore} isLoading={loading} isDisabled={noMore}>
          {noMore ? t('library.noMoreForms') : t('library.loadMore')}
        </Button>
      </VStack>
    </Box>
  );
};

export default Library;