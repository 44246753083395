import React from 'react';
import {
  VStack,
  FormControl,
  FormLabel,
  Input,
  NumberInput,
  NumberInputField,
  Box,
  Textarea,
  Select,
  CheckboxGroup,
  Checkbox,
  RadioGroup,
  Radio,
  Stack,
  Heading,
  Text,
  Divider
} from '@chakra-ui/react';

const FormPreview = ({ formData }) => {
  // Function to render form inputs based on type
  const renderFormInput = (item) => {
    switch (item.type) {
      case 'text':
        return <Input id={item.id} placeholder={item.name} />;
      case 'date':
        return <Input type="date" id={item.id} placeholder={item.name} />;
      case 'number':
        return (
          <NumberInput>
            <NumberInputField id={item.id} placeholder={item.name} />
          </NumberInput>
        );
      case 'multi_option':
        return (
          <CheckboxGroup colorScheme="green">
            <Stack spacing={[1, 5]} direction={['column', 'row']}>
              {item.options.map((option, index) => (
                <Checkbox key={index} value={option}>
                  {option}
                </Checkbox>
              ))}
            </Stack>
          </CheckboxGroup>
        );
      case 'select_option_metadata_comments':
        return (
          <Box>
            {item.listChoices.map((choice, index) => (
              <FormControl key={index} id={`${item.id}_${index}`}>
                <FormLabel>{choice}</FormLabel>
                <Select placeholder="Select option">
                  {item.options.map((option, optIndex) => (
                    <option key={optIndex} value={option.option}>
                      {option.option}
                    </option>
                  ))}
                </Select>
                {item.options.some(opt => opt.req_text) && (
                  <Textarea placeholder="Additional comments" mt={2} />
                )}
                {/* Implement additional logic if requiring images */}
              </FormControl>
            ))}
          </Box>
        );
      // Add more cases as necessary for different input types
      default:
        // Fallback to a simple text input
        return <Input id={item.id} placeholder={item.name} />;
    }
  };

  return (
    <VStack spacing={4} align="stretch" p={5}>
      <Text>{formData.description}</Text>
      <Divider my={4} />
      {formData.items.map((item, index) => (
        <FormControl key={index} id={item.id}>
          <FormLabel>{item.name}</FormLabel>
          {renderFormInput(item)}
        </FormControl>
      ))}
    </VStack>
  );
};

export default FormPreview;