// ProcessorFormAIGenerator.js

import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Spinner, Box, Heading, Text, Center, useToast } from '@chakra-ui/react';
import axios from 'axios';
import { updateForm } from '../../firebase.utils';
import { useTranslation } from 'react-i18next';
import { getToken } from 'firebase/app-check';
import { checkIfFormExists, saveFormToFirestore } from '../../firebase.utils'; 

const ProcessorFormAIGenerator = ({userToken, iframe}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isFetching, setIsFetching] = useState(false); 
  const navigate = useNavigate();
  const toast = useToast();
  const { runID, threadID } = useParams();
  const { t } = useTranslation();
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  console.log(backendUrl);

  useEffect(() => {
    let timeoutId = null; // Store the timeout ID for cleanup

    const checkStatus = async () => {
      if (isFetching) {
        return; // Exit if an existing fetch/save operation is in progress
      }
      setIsFetching(true);
      setIsLoading(true);

      if (await checkIfFormExists(userToken, runID)) {
        setIsLoading(false);
        setIsFetching(false); // Turn off the fetching flag
        navigate(`/${iframe ? 'iframe/' : ''}form/${runID}`);
        return;
      }

      try {
        const statusResponse = await axios.get(`${backendUrl}checkStatus`, {
          params: {
            runID,
            threadID,
            userToken  // Generally, tokens shouldn't be passed in URLs due to security concerns.
          }
        });


        // If we get a valid response, the isFetching flag stays true until navigating away
        if (statusResponse.data && statusResponse.data.success) {
          //await saveFormToFirestore(userToken, runID, statusResponse.data.openAiData, statusResponse.data.createFormData);
          localStorage.setItem('counterError', 0);
          navigate(`/${iframe ? 'iframe/' : ''}form/${runID}`);
          setIsLoading(false);

        } else {
          setIsLoading(false);
          setIsFetching(false); // Turn off the fetching flag before setting up the next poll
          timeoutId = setTimeout(checkStatus, 5000);
        }
      } catch (error) {
        setIsLoading(false);
        setIsFetching(false); // Error encountered, turn off the fetching flag
        console.log(error);
        console.log('error output', error.response.data);
        //if some error it will retry to generate it, if response json on message param contain error it will retry
        if(error.response.data.message.includes('invalid JSON') || error.response.data.message.includes('Error validating formData')){
          let message = "";
          if(error.response.data.message.includes('invalid JSON')){
            message =  `Correct this output to be a valid JSON object (without comments or incomplete output) that could fail on doing JSON.parse on javascript. Please output a final JSON. \n ${JSON.stringify(error.response.data.outputData)}`;
          }
          else{
            console.log('errors found', error.response.data.errors.join(", "));
            message =  `Correct this format errors ${error.response.data.errors.join(", ")} on this JSON object (don't use comments or incomplete output) that could fail on doing JSON.parse on javascript. Please output a final JSON. \n ${JSON.stringify(error.response.data.outputData)}`;
          }
          const params = {
            message: message,
            threadID: threadID
          };
          if (localStorage.getItem('counterError') === null) {
            // If not, set it to 1
            localStorage.setItem('counterError', 1);
          } else {
            // If it does, increment it by 1
            var counter = parseInt(localStorage.getItem('counterError'));
            localStorage.setItem('counterError', counter + 1);
          }

          if (parseInt(localStorage.getItem('counterError')) < 3) {
          let aiResponse;
          try{
          const aiResponse = await axios.post(`${backendUrl}runOpenAIAssistant`, params);
    
          setIsLoading(false);
          let runID = aiResponse.data.id;
          let threadID2 = aiResponse.data.thread_id;
          console.log(`/${ iframe ? 'iframe' : 'admin'}/process_generation/${runID}/thread/${threadID2}`);
          navigate(`/${ iframe ? 'iframe' : 'admin'}/process_generation/${runID}/thread/${threadID2}`);
          return;
          }
          catch (error) {
            navigate(`/${ iframe ? 'iframe' : 'admin'}/process_generation/${runID}/thread/${threadID}`);

          }
        }
        else{
          toast({
            title: 'Error Generating Form',
            description: "Error generating form, please try again.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          navigate(`/${ iframe ? 'iframe' : 'admin'}/new_form`);

        }
        }
        else{
        
          toast({
            title: 'Error Generating Form',
            description: "Error generating form, please try again.",
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
          navigate(`/${ iframe ? 'iframe' : 'admin'}/new_form`);
      }
      }
    };

    checkStatus(); // First invocation of checkStatus
    return () => clearTimeout(timeoutId); // Cleanup on effect unmount
  }, [runID, threadID, userToken, navigate]);


  return (
    <Center flexDirection="column" mt="20">
    <Spinner size="xl" thickness="4px" mb={6} />
    <Heading mb={2}>{t('processingAssessment')}</Heading>
    <Text color={'gray.500'}>{t('processingInfo')}</Text>
  </Center>
  );
};

export default ProcessorFormAIGenerator;