import React, { useState, useEffect, Suspense } from 'react';
import { Box, VStack, Heading, Button, Modal,
    ModalOverlay,
    Flex,
    ModalContent,
    useToast,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
    AlertDialog,
    AlertDialogOverlay,
    AlertDialogContent,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogFooter,
    Center,
    Tooltip,
    useDisclosure,
    useBreakpointValue,
    Text,
    IconButton } from '@chakra-ui/react';
  import mixpanel from 'mixpanel-browser';
  import { getFormById } from '../firebase.utils';
  import { Helmet } from 'react-helmet'; 
  import {DownloadIcon, EditIcon} from '@chakra-ui/icons';
  import Layout from './Layout';
  import { useNavigate, useParams } from 'react-router-dom';
  import axios from 'axios';
  import { useTranslation } from 'react-i18next';
  import SEO from './SEO';
  
  const backendUrl = process.env.REACT_APP_BACKEND_URL;
  
  const FormPreview = React.lazy(() => import('./FormPreview'));

  
  
  const FormLanding = ({userToken, language}) => {
    const { formId } = useParams();
    const [form, setForm] = useState(null);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { t } = useTranslation();
    const navigate = useNavigate();
    const toast = useToast();
    const [isConfirmOpen, setIsConfirmOpen] = useState(false);
    const cancelRef = React.useRef();
    const isMobile = useBreakpointValue({ base: true, md: false });
  
    useEffect(() => {
      const fetchForm = async () => {
        const fetchedForm = await getFormById(formId);
        setForm(fetchedForm);
      };
      fetchForm();
    }, [formId]);
  
  
    const handleImportClick = () => {
      if (userToken) {
        setIsConfirmOpen(true);
      } else {
        // Prompt user to log in
        // ...
      }
    };
  
    const handleImport = async () => {
      if (!userToken) {
        handleAuthRequiredAction('modify');
        try{
        mixpanel.track('Try to Import', { formId });
        }
        catch (error) {
          console.log(error);
        }
    
      } else {
        try {
          const importFormResponse = await axios.post(`${backendUrl}importForm`, {
            userToken,
            formId
          });
  
          try{
          mixpanel.track('Import Form', { formId });
          }
          catch (error) {
            console.log(error);
          }
    
          if (importFormResponse.data.success) {
            console.log('Form imported successfully:', importFormResponse.data);
            navigate(`/form/${importFormResponse.data.documentId}`);
          } else {
            throw new Error(importFormResponse.data.message);
          }
    
          
        } catch (error) {
          setIsConfirmOpen(false);
          console.error('Error importing form: ', error.response || error.message);
          toast({
            title: t('error_importing_form'),
            description: t('error_importing_form_description'),
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      }
    };
  
    const handleAuthRequiredAction = (redirectType) => {
      if (!userToken) {
        const formDetails = encodeURIComponent(JSON.stringify({
          title: form.title || t('preview_form_title'),
          subtitle: null,
          image: null,
          description: form.formData.description
        }));
        onOpen(redirectType, formDetails);
      } else {
        // Handle the action that requires authentication
      }
    };
  
    const redirectToLogin = (redirectType, formDetails) => {
      onClose(); // Close the modal first
      navigate(`/?redirect=${redirectType}&title=${encodeURIComponent(form.title)}&description=${encodeURIComponent(form.formData.description)}`);
    };
  
    if (!form) {
      return <Box>{t('loading')}</Box>;
    }
  
    return (
      <>
         <SEO language={language} title={'FormBuilderGPT | ' + form.title} description={form.formData.description} />
      <Button size='xs' onClick={() => navigate('/forms-library')}>{t('back_library')}</Button>
      <VStack spacing={8}>
        <Flex justifyContent="space-between" alignItems="flex-start" width="100%">
          <VStack align="flex-start">
            <Heading>{form.title}</Heading>
            <Box>{form.formData.description}</Box>
          </VStack>
          <Tooltip hasArrow label={t('import_button_tooltip')}>
            {isMobile ? (
              <IconButton 
                aria-label={t('import_form')}
                icon={<DownloadIcon />}
                colorScheme="blue" 
                onClick={() => { !userToken ? handleAuthRequiredAction('modify') : handleImportClick() }} 
              />
            ) : (
              <Button 
                colorScheme="blue"
                onClick={() => { !userToken ? handleAuthRequiredAction('modify') : handleImportClick() }}
                leftIcon={<DownloadIcon />}
                minW="fit-content"
                px={4}
              >
                {t('import_form')}
              </Button>
            )}
          </Tooltip>
          </Flex>
        <Flex onClick={() => !userToken ? handleAuthRequiredAction('modify') : handleImportClick()}>
        <Suspense fallback={<div>{t('loading')}</div>}>
        <FormPreview formData={form.formData} />
        </Suspense>
        </Flex>
      </VStack>
      <AlertDialog
          isOpen={isConfirmOpen}
          leastDestructiveRef={cancelRef}
          onClose={() => setIsConfirmOpen(false)}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                {t('import_form')}
              </AlertDialogHeader>
              <AlertDialogBody>
                {t('are_you_sure_import_form')}
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={() => setIsConfirmOpen(false)}>
                  {t('cancel')}
                </Button>
                <Button colorScheme="blue" onClick={handleImport} ml={3}>
                  {t('yes_import')}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      <Modal isOpen={isOpen} onClose={() => onClose()}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{t('welcome_to_formsai')}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <VStack spacing={4}>
                <Text fontSize="lg" fontWeight="bold" textAlign="center">
                  {t('join_us_and_unlock')}
                </Text>
                <Center>
                </Center>
                <Text textAlign="center">
                  {t('dont_miss_out')}
                </Text>
                <Button
                  leftIcon={<DownloadIcon />}
                  colorScheme="blue"
                  width="full"
                  onClick={() => redirectToLogin(`/forms-library/${formId}`, form.formData.description)}
                >
                  {t('import_form_and_get_started')}
                </Button>
                <Button
                  leftIcon={<EditIcon />}
                  colorScheme="green"
                  width="full"
                  onClick={() => redirectToLogin('create', form.formData.description)}
                >
                  {t('create_new_form_with_ai')}
                </Button>
              </VStack>
            </ModalBody>
            <ModalFooter>
              <Button variant="outline" colorScheme="blue" onClick={() => onClose()}>
                {t('maybe_later')}
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    );
  };
  
  export default FormLanding;