import React from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

const SEO = ({ title, description, imageUrl, language }) => {
  const { t } = useTranslation();

  // Utilizing i18n translations for dynamic default values based on the current language
  const defaultTitle = t('seo_default_title');
  const defaultDescription = t('seo_default_description');
  // Example default image URL, replace with your actual default image URL
  const defaultImageUrl = "https://formbuilder-gpt.datascope.io/logo-white.png";

  const structuredData = {
    "@context": "http://schema.org",
    "@type": "WebApplication",
    "name": title || defaultTitle,
    "url": "https://formbuilder-gpt.datascope.io",
    "description": description || defaultDescription,
    "image": imageUrl || defaultImageUrl,
    "applicationCategory": "BusinessApplication"
  };

  return (
    <Helmet htmlAttributes={{ lang: language }}>
      <title>{title || defaultTitle}</title>
      <meta name="description" content={description || defaultDescription} />
      <meta property="og:title" content={title || defaultTitle} />
      <meta property="og:description" content={description || defaultDescription} />
      <meta property="og:image" content={imageUrl || defaultImageUrl} />
      <meta name="twitter:description" content={description || defaultDescription} />
      <meta property="og:url" content="https://formbuilder-gpt.datascope.io" />
      
      {/* Additional recommended meta tags for better SEO */}
      <meta property="og:type" content="website" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:image:src" content={imageUrl || defaultImageUrl} />
      <script type="application/ld+json">
        {JSON.stringify(structuredData)}
      </script>
    </Helmet>
  );
};

export default SEO;