import React from 'react';
import {
  Avatar,
  Box,
  Flex,
  Heading,
  IconButton,
  useDisclosure,
  Drawer,
  DrawerBody,
  List,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Menu,
  MenuButton,
  Button,
  Link,
  Icon,
  MenuItem,
  ListIcon,
  ListItem,
  MenuList,
  useBreakpointValue,
  VStack,
  Text,
  Divider,
  Link as ChakraLink
} from '@chakra-ui/react';
import { HamburgerIcon, ViewIcon, AddIcon, InfoOutlineIcon } from '@chakra-ui/icons';
import { Link as RouterLink } from 'react-router-dom';
import {
  useTranslation
} from 'react-i18next';

const AdminComponent = ({ children, user, handleLogout, iframe }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { t } = useTranslation();

  const SidebarContent = (
    <List spacing={2} style={{ width: "100%" }}>
      <ListItem paddingLeft={4} paddingRight={4} paddingTop={2} paddingBottom={2}>
        <ChakraLink as={RouterLink} to="/admin/new_form">
          <Flex align="center">
            <Icon as={AddIcon} mr={2} />
            <Text fontSize="lg" fontWeight="medium">{t('new_form')}</Text>
          </Flex>
        </ChakraLink>
      </ListItem>
      <Divider />
      <ListItem paddingLeft={4} paddingRight={4} paddingTop={2} paddingBottom={2}>
        <ChakraLink as={RouterLink} to="/forms">
          <Flex align="center">
            <Icon as={ViewIcon} mr={2} />
            <Text fontSize="lg" fontWeight="medium">{t('forms')}</Text>
          </Flex>
        </ChakraLink>
      </ListItem>
      <ListItem paddingLeft={4} paddingRight={4} paddingTop={2} paddingBottom={2}>
        <ChakraLink as={RouterLink} to="/forms-library">
          <Flex align="center">
            <Icon as={InfoOutlineIcon} mr={2} />
            <Text fontSize="lg" fontWeight="medium">{t('forms_library')}</Text>
          </Flex>
        </ChakraLink>
      </ListItem>
      {/* Repeat similar ListItem structure for additional sidebar items as needed */}
    </List>
  );

  let header =   (<Flex
  as="header"
  align="center"
  justify="space-between"
  wrap="wrap"
  p={4}
  bg="blue.600"
  color="white"
>
  {isMobile && (
    <IconButton
      icon={<HamburgerIcon />}
      onClick={onOpen}
      variant="outline"
      aria-label="Open menu"
      size="lg"
      color="white"
    />
  )}

  <Flex align="center" ml={isMobile ? 0 : 10} mr={5}>
    <Heading as="h1" size="lg" letterSpacing={"tighter"}>
      FormBuilderGPT
    </Heading>
    {!isMobile && (
      <img src="/logo-white.png" alt="DataScope Logo" style={{ marginLeft: "10px", maxWidth: "100px" }} />
    )}
  </Flex>

  <Menu>
    <MenuButton as={Button} bg="transparent" color="white" _hover={{ bg: 'blue.500' }} _expanded={{ bg: 'blue.500' }} rightIcon={<Avatar size="sm" name={user?.displayName} src={user?.photoURL} />} >
    {t('sign_out')}
    </MenuButton>
    <MenuList color="gray.800" bg="white">
      <MenuItem onClick={handleLogout}> {t('sign_out')}</MenuItem>
    </MenuList>
  </Menu>
</Flex>);

let sidebar = (
  <Flex flex="1" direction={{ base: 'column', md: 'row' }}>
  {/* Sidebar */}
  { !iframe && (
  <Box
    width={{ md: '250px' }}
    bg="gray.100" // changed from `blue.600` to a lighter color
    p="4"
    display={{ base: 'none', md: 'block' }}
  >
    <Flex direction="column" h="full" justifyContent="space-between">
      <VStack spacing={4} align="stretch">
        {SidebarContent}
      </VStack>
      <Box>
        <Divider my={4} />
        <Text fontSize="sm" textAlign="center">&copy; {new Date().getFullYear()} FormBuilderGPT</Text>
        <Text fontSize="sm" textAlign="center">Powered by DataScope</Text>
      </Box>
    </Flex>
  </Box>) }

  {/* Main Content */}
  <Box flex="1" p="4">
    {children}
  </Box>
</Flex>);

  return (
    <Flex direction="column" minHeight="100vh">
      {/* Top bar remains the same */}
      { !iframe && header}

      {/* Sidebar and Content */}
      {sidebar}

      {/* Mobile Menu Drawer */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerBody p={4}>
            {SidebarContent}
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </Flex>
  );
};

export default AdminComponent;