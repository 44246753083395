// Forms.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { List, ListItem, Flex, Button, Box, Heading, Text } from '@chakra-ui/react';
import { getFormsList } from '../firebase.utils'; // Make sure this is implemented to fetch all forms

const Forms = ({ userToken }) => {
  const [formsList, setFormsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    const fetchFormsList = async () => {
      const list = await getFormsList(userToken);
      setFormsList(list);
      setIsLoading(false);
    };

    fetchFormsList();
  }, []);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box p={5}>
      <Heading mb={4}>All Forms</Heading>
      <List spacing={3}>
        {formsList.length ? (
          formsList.map((form) => (
            form.createFormData.form_created && (
            <ListItem key={form.formId || form.openAiData.data[0].run_id} p={3} borderWidth="1px" borderRadius="md" display="flex" justifyContent="space-between" alignItems="center">
              <Text fontWeight="semibold">{form.title}</Text>
              <Button colorScheme="blue" onClick={() => navigate(`/form/${form.formId ||  form.openAiData.data[0].run_id}`)}>
                View
              </Button>
            </ListItem>)
          ))
        ) : (
          <Text>No forms available.</Text>
        )}
      </List>
    </Box>
  );
};

export default Forms;