import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDoc, doc, setDoc, where, query, orderBy, limit, startAfter, getDocs, updateDoc, serverTimestamp, writeBatch } from 'firebase/firestore';
import { getAuth, } from 'firebase/auth';
import { initializeAppCheck, ReCaptchaEnterpriseProvider } from 'firebase/app-check';
import axios from 'axios';


const firebaseConfig = {
  apiKey: "AIzaSyB2of26FdPdMKfpq7W7YwsSVFDOkzG9_HY",
  authDomain: "forms-ai-8f89a.firebaseapp.com",
  projectId: "forms-ai-8f89a",
  storageBucket: "forms-ai-8f89a.appspot.com",
  messagingSenderId: "1043517711374",
  appId: "1:1043517711374:web:802b5fb8e5f84c33c1c85f",
  measurementId: "G-0878E2CP3L"
};

// Initialize Firebase and Firestore
const appFirebase = initializeApp(firebaseConfig);
const db = getFirestore(appFirebase);
const auth = getAuth(appFirebase);

//const appCheck = initializeAppCheck(appFirebase, {provider: new ReCaptchaEnterpriseProvider('6LfY3UwpAAAAAJavyf8kUwO35tcefvBAqEgRbkON') });
const appCheck = {};


const backendUrl = process.env.REACT_APP_BACKEND_URL;


export const checkIfFormExists = async (userToken, runId) => {
  try {
    const response = await axios.post(`${backendUrl}checkIfFormExists`, {
      userToken,
      runId,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data.exists;
  } catch (error) {
    console.error('Error checking if form exists:', error);
    throw error;
  }
};

export const getForm = async (runId, userToken) => {
  try {
    const response = await axios.post(`${backendUrl}getForm`, {
      runId,
      userToken,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error getting form data:', error);
    throw error;
  }
};

export const fetchUserFeedback = async (formId, userToken) => {
  try {
    const response = await axios.post(`${backendUrl}fetchFeedback`, {
      userToken,
      formId
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data.data;
  } catch (error) {
    console.error('Error fetching forms list:', error);
    throw error;
  }

};

export const saveUserFeedback = async (formId, rating, details, title, userToken) => {
  try {
    const response = await axios.post(`${backendUrl}saveFeedback`, {
      userId: userToken,
      formId,
      rating,
      title,
      details
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching forms list:', error);
    throw error;
  }
};

// Utility to fetch all forms
export const getFormsList = async (userToken) => {
  try {
    const response = await axios.post(`${backendUrl}getFormsList`, {
      userToken,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching forms list:', error);
    throw error;
  }
};

// Function to save form to Firestore
export const saveFormToFirestore = async (userToken, runId, openAiData, createFormData) => {
  try {
    const response = await axios.post(`${backendUrl}saveFormToFirestore`, {
      userToken,
      runId,
      openAiData,
      createFormData,
    }, {
      headers: {
        'Content-Type': 'application/json',
      }
    });
    return response.data;
  } catch (error) {
    console.error('Error saving form to Firestore:', error);
    throw error;
  }
};

export const getLibraryForms = async (lastVisible = null, language = 'en') => {
  try {
    let q;
    // Adjust the query based on whether there's a lastVisible document for pagination and if language filtering is applied
    if (lastVisible) {
      q = query(
        collection(db, 'library'), 
        where('formData.language', '==', language), 
        orderBy('importedAt', 'desc'),
        startAfter(lastVisible),
        limit(20)
      );
    } else {
      q = query(
        collection(db, 'library'), 
        where('formData.language', '==', language), 
        orderBy('importedAt', 'desc'),
        limit(20)
      );
    }
    const querySnapshot = await getDocs(q);
    const forms = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    // Determine the last document of the current batch for pagination
    const newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
    return { forms, lastVisible: newLastVisible };
  } catch (error) {
    console.error('Error fetching library forms:', error);
    throw error;
  }
};

export const searchLibraryForms = async (searchTerm, language, lastVisible = null) => {
  try {
    let q;
    // Dynamically adjust the query based on the presence of a searchTerm, language, and lastVisible document for pagination
    if (lastVisible) {
      q = query(
        collection(db, 'library'), 
        where('formData.language', '==', language), 
        // Assume 'title' is the field to search on; adjust as necessary for your data model
        where('title', '>=', searchTerm), 
        where('title', '<=', searchTerm + '\uf8ff'), 
        orderBy('title'),
        startAfter(lastVisible),
        limit(20)
      );
    } else {
      q = query(
        collection(db, 'library'), 
        where('formData.language', '==', language),
        // Assume 'title' is the specific field we're searching in; this might need adjustment based on your data structure
        where('title', '>=', searchTerm), 
        where('title', '<=', searchTerm + '\uf8ff'), 
        orderBy('title'),
        limit(20)
      );
    }
    const querySnapshot = await getDocs(q);
    const forms = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1]; // Determine the last document of the current batch for pagination
    return { forms, lastVisible: newLastVisible };
  } catch (error) {
    console.error('Error searching library forms:', error);
    throw error;
  }
};

export const fetchLastForms = async (language) => {
  const formsRef = collection(db, 'library');
  const queryLastForms = language 
                        ? query(formsRef, where('formData.language', '==', language), orderBy('importedAt', 'desc'), limit(6))
                        : query(formsRef, orderBy('importedAt', 'desc'), limit(6));

  const querySnapshot = await getDocs(queryLastForms);
  return querySnapshot.docs.map((doc) => ({ id: doc.id, data: doc.data() }));
};


export const getFormById = async (formId) => {
  try {
    const docRef = doc(db, 'library', formId);
    const docSnap = await getDoc(docRef);

    if (docSnap.exists()) {
      return { id: docSnap.id, ...docSnap.data() };
    } else {
      console.error("No such document!");
    }
  } catch (error) {
    console.error('Error getting form:', error);
    throw error;
  }
};

export {auth, db, appFirebase, appCheck};
