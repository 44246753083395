import { extendTheme } from '@chakra-ui/react'

const theme = extendTheme({
  colors: {
    blue: {
      50: '#ebf5ff',
      100: '#d1e6ff',
      200: '#a3cdff',
      300: '#75b4ff',
      400: '#3e8ffa',
      500: '#3e8ffa',
      600: '#3880e0',
      700: '#3271c7',
      800: '#2b62ae',
      900: '#245395'
    }
  }
})

export default theme 