import axios from 'axios';
import { getFunctions, httpsCallable } from 'firebase/functions';

const backendUrl = process.env.REACT_APP_BACKEND_URL;

export const createUser = async (email, password, additionalData) => {
    try {
      const response = await axios.post(`${backendUrl}createUser`, {
        email,
        password,
        ...additionalData
      }, {
        headers: {
          'Content-Type': 'application/json',
        }});
      return response.data;
    } catch (error) {
        if (error.response && error.response.status === 422) {
            // The request was made and the server responded with a status code 422
            // Handle validation error here, access error.response.data
            console.error('Validation error: ', error.response.data);
            // Return or throw a specific error for your application to handle
            console.log('response data', error.response.data);
            return error.response.data;
          } else {
            // Any other errors that didn't respond with a 422 status
            console.error('An error occurred during account creation:', error.message);
            throw error; // Rethrow the error if it's not a 422 status
          }
    }
  };

export const signIn = async (email, password) => {
    try {
      const response = await axios.post(`${backendUrl}signIn`, {
        email,
        password
      }, {
        headers: {
          'Content-Type': 'application/json',
        }});
      return response.data;
    } catch (error) {
      console.error('An error occurred during the sign-in process:', error);
      if (error.response && error.response.status === 422) {
        // The request was made and the server responded with a status code 422
        // Handle validation error here, access error.response.data
        console.error('Validation error: ', error.response.data);
        // Return or throw a specific error for your application to handle
        console.log('response data', error.response.data);
        return error.response.data;
      } else {
        // Any other errors that didn't respond with a 422 status
        console.error('An error occurred during account creation:', error.message);
        throw error; // Rethrow the error if it's not a 422 status
      }
    }
  };