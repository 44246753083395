import React from 'react';
import { Box, Flex, Text, VStack, Container, Heading, Menu, MenuList, MenuItem, MenuButton, Button, Avatar,  useBreakpointValue, } from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ChevronDownIcon } from '@chakra-ui/icons';

const Header = ({userToken, handleLogout, language}) => {
    const { t } = useTranslation();
    const isMobile = useBreakpointValue({ base: true, md: false });
    
    const languageNames = {
      'es': 'Español',
      'en': 'English',
      'pt': 'Português'
    };
    return  (
      <Flex
      as="header"
      w="full"
      p={5}
      bg="blue.600"
      color="white"
      align="center"
      justify="space-between"
      position="fixed"
      top={0}
      left={0}
      right={0}
      zIndex="docked"
    >
      <Container maxW="container.xl" display="flex" justifyContent="space-between" alignItems="center">
    <Flex align="center" ml={isMobile ? 0 : 10} mr={5}>
    <Flex align="center" mr={30}>
      <Heading as="h1" size="lg" letterSpacing="tighter" color="white">
        FormBuilderGPT
      </Heading>
      <Box ml="2">
        <img src="/logo-white.png" alt={t('datascope_logo_alt_text')} style={{ maxWidth: '100px' }} />
      </Box>
    </Flex>

    <Menu >
      <MenuButton as={Button} variant="unstyled">
        <Flex align="right">
          <Text>
            {languageNames[language.toLowerCase()]}
          </Text>
          <ChevronDownIcon />
        </Flex>
      </MenuButton>
      <MenuList bg="white">
        <MenuItem as='a' href="/?language=es" bg="white" color="grey">
          Español
        </MenuItem>
        <MenuItem as='a' href="/?language=en" bg="white" color="grey">
          English
        </MenuItem>
        <MenuItem as='a' href="/?language=pt" bg="white" color="grey">
          Português
        </MenuItem>
      </MenuList>
    </Menu>
      </Flex>
      { userToken &&
        (<Menu>
          <MenuButton as={Button} bg="transparent" color="white" _hover={{ bg: 'blue.500' }} _expanded={{ bg: 'blue.500' }} rightIcon={<Avatar size="sm" />} >
          {t('sign_out')}
          </MenuButton>
          <MenuList color="gray.800" bg="white">
            <MenuItem onClick={handleLogout}> {t('sign_out')}</MenuItem>
          </MenuList>
        </Menu>)
        }
        </Container>
    </Flex>);
};

const Footer = () => (
  <Box as="footer" bg="gray.100" color="gray.600" py={4}>
    <Container maxW="container.xl">
      <Flex justify="space-between" align="center">
        <Text>&copy; {new Date().getFullYear()} FormsAI. All rights reserved.</Text>
        {/* Add additional footer content as needed */}
      </Flex>
    </Container>
  </Box>
);

const Layout = ({ children, userToken, handleLogout, language = 'en' }) => {
  return (
    <VStack spacing={0} align="stretch" minHeight="100vh">
      <Header userToken={userToken} handleLogout={handleLogout} language={language} />
      <Box as="main" flex="1">
        <Container maxW="container.xl" pt={100}>
          {children}
        </Container>
      </Box>
      <Footer />
    </VStack>
  );
};

export default Layout;